import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Image,
} from "@chakra-ui/react";
import {
  useCreateDefaultAddress,
  useEditAddress,
  useFetchDefaultAddress,
  usePostNewAddress,
  useUpdateDefaultAddress,
} from "features/addressPopup/api/client";
import { PersonalInformationSchema } from "features/addressPopup/components/medicationSidebar/validationSchemas";
import {
  useDashboardInfo,
  useFetchProfileInfo,
} from "features/dashboard/api/client";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

import { FormValuesPersonal } from "../api/types";

import popup_bg from "assets/images/popup_bg.png";
import cross from "assets/images/cross.svg";
import { useTranslation } from "react-i18next";

const PersonalInformation = () => {
  const { t } = useTranslation();

  const { data: profileData } = useFetchProfileInfo();

  const { data: defaultAddressData } = useFetchDefaultAddress();

  const { trigger: dispatchEditForm, isMutating: isEditAddressLoading } =
    useUpdateDefaultAddress(defaultAddressData?.id ?? "");
  const { trigger: saveNewAddress, isMutating: isCreateAddressLoading } =
    useCreateDefaultAddress();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  const formik = useFormik<FormValuesPersonal>({
    initialValues: {
      first_name: profileData?.first_name ?? "",
      last_name: profileData?.last_name ?? "",
      phone: "",
      email: "",
    },
    onSubmit: async (values) => {
      try {
        if (defaultAddressData) {
          await dispatchEditForm({ address: values });
        } else {
          await saveNewAddress({
            address: {
              ...values,
              zipCode: "",
              address: "",
              city: "",
              country: "",
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
      handleOpen();
    },
    validationSchema: PersonalInformationSchema,
    validateOnChange: false,
  });

  useEffect(() => {
    if (defaultAddressData) {
      if (
        defaultAddressData.first_name !== "" &&
        defaultAddressData.last_name !== "" &&
        defaultAddressData.phone !== "" &&
        defaultAddressData.email !== ""
      ) {
        formik.setValues({
          first_name: defaultAddressData.first_name,
          last_name: defaultAddressData.last_name,
          phone: defaultAddressData.phone,
          email: defaultAddressData.email,
        });
      }
    }
  }, [defaultAddressData]);

  useEffect(() => {
    if (!defaultAddressData || !profileData) return;

    if (
      (!defaultAddressData.first_name ||
        defaultAddressData.first_name === "") &&
      (!defaultAddressData.last_name || defaultAddressData.last_name === "")
    ) {
      debugger;
      dispatchEditForm({
        address: {
          ...defaultAddressData,
          first_name: profileData.first_name,
          last_name: profileData.last_name,
        },
      });
    }
  }, [defaultAddressData, profileData]);

  const isFormValid = formik.isValid;

  return (
    <>
      <Flex direction="column" w="100%">
        <Heading fontSize="20px" fontWeight={500} color="blueish.500">
          {t("EDIT YOUR PERSONAL INFORMATION")}
        </Heading>
        <Text color="blackish.200" fontSize="16px" fontWeight={400}>
          {t("This information will not be published.")}
        </Text>

        <form onSubmit={formik.handleSubmit}>
          <Flex
            gap={5}
            mt="2rem"
            w="100%"
            direction={{ base: "column", sm: "row" }}
          >
            <FormControl
              color="blackish.200"
              isInvalid={!!formik.errors.first_name}
            >
              <FormLabel
                htmlFor="first_name"
                color="blackish.200"
                fontWeight={700}
                fontSize="16px"
              >
                {t("First name")}
              </FormLabel>
              <Input
                disabled={
                  !profileData?.first_name || profileData?.first_name !== ""
                }
                id="first_name"
                name="first_name"
                type="text"
                placeholder={`${t("Enter your first name")}`}
                onChange={formik.handleChange}
                value={formik.values.first_name}
                _disabled={{
                  opacity: 0.9,
                  cursor: "not-allowed",
                  filter: "grayscale(0.5)",
                }}
              />
              <FormErrorMessage color="red">
                {formik.errors.first_name}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              color="blackish.200"
              isInvalid={!!formik.errors.last_name}
            >
              <FormLabel
                htmlFor="last_name"
                color="blackish.200"
                fontWeight={700}
                fontSize="16px"
              >
                {t("Last name")}
              </FormLabel>
              <Input
                id="last_name"
                disabled={
                  !profileData?.last_name || profileData?.last_name !== ""
                }
                name="last_name"
                type="text"
                placeholder={`${t("Enter your last name")}`}
                onChange={formik.handleChange}
                value={formik.values.last_name}
                _disabled={{
                  opacity: 0.9,
                  cursor: "not-allowed",
                  filter: "grayscale(0.5)",
                }}
              />
              <FormErrorMessage color="red">
                {formik.errors.last_name}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex
            gap={5}
            mt="2rem"
            w="100%"
            direction={{ base: "column", sm: "row" }}
          >
            <FormControl color="blackish.200" isInvalid={!!formik.errors.email}>
              <FormLabel
                htmlFor="email"
                color="blackish.200"
                fontWeight={700}
                fontSize="16px"
              >
                {t("Email")}
              </FormLabel>
              <Input
                id="email"
                name="email"
                type="text"
                placeholder={`${t("Enter your email address")}`}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <FormErrorMessage color="red">
                {formik.errors.email}
              </FormErrorMessage>
            </FormControl>

            <FormControl color="blackish.200" isInvalid={!!formik.errors.phone}>
              <FormLabel
                htmlFor="phone"
                color="blackish.200"
                fontWeight={700}
                fontSize="16px"
              >
                {t("Phone number")}
              </FormLabel>
              <Input
                id="phone"
                name="phone"
                type="text"
                placeholder={`${t("Enter your phone number")}`}
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              <FormErrorMessage color="red">
                {formik.errors.phone}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </form>
        <Flex
          gap={2}
          direction={{ base: "column", sm: "row" }}
          mt="2rem"
          mx={{ base: "3rem", sm: "0rem" }}
        >
          <Button
            variant="filledBlue"
            onClick={() => {
              formik.submitForm();
              console.log("submited");
            }}
          >
            <Text fontSize={{ base: "18px", sm: "20px" }}>
              {t("Save Changes")}
            </Text>
          </Button>

          <Button
            variant="whited"
            onClick={() => {
              if (defaultAddressData) {
                formik.setValues({
                  first_name: defaultAddressData.first_name,
                  last_name: defaultAddressData.last_name,
                  phone: defaultAddressData.phone,
                  email: defaultAddressData.email,
                });
              } else {
                formik.resetForm();
              }
            }}
          >
            <Text fontSize={{ base: "16px", sm: "20px" }} color="blueish.500">
              {t("Cancel")}
            </Text>
          </Button>
        </Flex>
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
          isCentered
          size={{ base: "sm", md: "md" }}
        >
          <ModalOverlay />
          <ModalContent backgroundImage={popup_bg} borderRadius={"20px"}>
            <ModalHeader>
              <Flex justify="flex-end">
                <Image
                  onClick={handleClose}
                  src={cross}
                  cursor="pointer"
                  alt={"close icon"}
                />
              </Flex>
            </ModalHeader>
            <ModalBody w="100%">
              <Flex
                direction="column"
                gap="40px"
                w="100%"
                p="40px"
                align="center"
              >
                <Heading fontSize="36px" color="white" textAlign="center">
                  {t("Your data has been updated.")}
                </Heading>
                <Button
                  variant="popup"
                  minW={{ sm: "none", md: "240px" }}
                  fontSize={{ base: "md" }}
                  onClick={handleClose}
                >
                  {t("Okay")}
                </Button>
              </Flex>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
};

export default PersonalInformation;
