import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  Text,
} from "@chakra-ui/react";
import { WrittenByType } from "features/admin/api/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface NoteListItemProps {
  date: Date;
  text: string;
  written_by: WrittenByType;
}

const NoteListItem: FunctionComponent<NoteListItemProps> = ({
  date,
  text,
  written_by,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      bg={"#589AAF"}
      p={"1rem"}
      fontSize={"1rem"}
      borderRadius={"1rem"}
      position={"relative"}
      pb={"1.5rem"}
      minW={"10rem"}
    >
      <Text fontSize={"inherit"}>{text}</Text>

      <Text fontSize={"14px"} fontWeight={"500"}>
        Skrivet av: {t(written_by?.role || "")} {written_by.name}
      </Text>

      <Text
        position={"absolute"}
        bottom={"0.25rem"}
        right={"1rem"}
        fontSize={"12px"}
        fontWeight={"600"}
      >
        {date?.toLocaleString(t("locale") ?? "en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </Text>
    </Box>
  );
};

export default NoteListItem;
